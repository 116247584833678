import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Field, reduxForm, reset } from 'redux-form';
import { useTranslation } from "react-i18next";

import Button from '../../../components/Button';
import CustomizedInputs from '../../../components/UIform';
import Popover from '../../../components/Popover';
import Loading from '../../../components/Loading';
import {required} from 'redux-form-validators';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import SnackbarContent from '@material-ui/core/SnackbarContent';

let CategoriesForm = (props) => {
    const { handleSubmit } = props;
    const classes = useStyles();
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const { t } = useTranslation();

    let hiddenCategories = [];

    if (props.hiddenCategories) {
      hiddenCategories = [...props.hiddenCategories];
    }

      const handleDelete = (chipToDelete) => () => {
        let list = props.hiddenCategories.filter((chip) => chip !== chipToDelete);
        props.updateVisibility(list, 'hiddenCategories');
      };

      const addChip = (data) => {
        let status = hiddenCategories.find((chip) => chip === data.id);
        if(status === undefined){
            let list = [...props.hiddenCategories, data.id];
            props.updateVisibility(list, 'hiddenCategories');
            props.reset();
        } else {
            alert(t('menu.duplicateId'))
        }
      };

      const saveData = () => {
        props.saveData('hiddenCategories')
      }

    return(
    <div className={classes.categoriesWrapper}>
      <div className={classes.headerWrap}>
        <Typography variant="h6" className={classes.title}>{t('navigation.categories')}</Typography>
        <Popover className={classes.popover} title="" hiddenTitle={t('menu.hideCategory')} />
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.tagContainer}>
            {hiddenCategories.map((data, index) => {
                return (
                  <li key={index}>
                    <Chip
                      label={data}
                      onDelete={handleDelete(data)}
                      className={classes.chip}
                    />
                    {isMin600 && <hr className={classes.hr} />}
                    <hr className={classes.hr} />
                  </li>
                );
              })}
            </div>
            <form onSubmit={handleSubmit} className={classes.formContainer} autoComplete='off'>
                <Field  name="id"
                        className={classes.input}
                        component={CustomizedInputs}
                        type="text"
                        placeholder={t('menu.enterCategory')}
                        rows={'1'}
                        disabled={props.loadStatus ? true : false}
                        validate={required({msg: t('loginScreen.requiredField')})}/>

                    <Button text={props.loadStatus ? 
                        <Loading size={22} color={'#fff'}/> : t('menu.addToList')} 
                                    disabled={props.loadStatus ? true : false}
                                    handleClick={handleSubmit(addChip)}
                                    className={classes.addToList} />
                    <div className={classes.bt}>
                      <Button text={props.loadStatus ? 
                          <Loading size={22} color={'#fff'}/> : t('buttons.save')} 
                                      disabled={props.loadStatus ? true : false}
                                      handleClick={saveData}
                                      className={classes.saveButton} />
                    </div>
              </form>
          {props.isActiveCategoriesSnackbar && 
            <SnackbarContent className={classes.snackbar} message={t('menu.savedInfo')} />}
        </div>
      </div>
    </div>
    )
}

const useStyles = makeStyles(theme => ({
    categoriesWrapper: {
      marginTop: '16px',
      padding: '24px 24px 16px 16px',
      border: '0.5px solid #DADCE0',
      boxSizing: 'border-box',
      borderRadius: '5px',
      [theme.breakpoints.down("600")]: {
        padding: '16px',
      }
    },
    snackbar: {
      marginTop: 16,
      '&.MuiSnackbarContent-root': {
        background: 'rgba(50, 109, 255, .05)',
        boxShadow: 'none',
        color: 'black',
        width: '100%',
        [theme.breakpoints.down("600")]: {
          textAlign: 'center',
          marginTop: 15,
        }
      },
      '& > .MuiSnackbarContent-message': {
        padding: '4px 0',
        [theme.breakpoints.down("600")]: {
          width: '100%',
        }
      }
    },
    title: {
      marginBottom: 10,
      fontSize: 22,
      lineHeight: '29px',
      fontWeight: 'bold',
      [theme.breakpoints.down("600")]: {
        display: 'none'
      }
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    row: {
        display: 'flex',
        // margin: 15,
        flexWrap: 'wrap',
        width: '100%',
        [theme.breakpoints.down("600")]: {
          margin: 0,
        }
    },
    chip: {
        margin: theme.spacing(0.5),
        backgroundColor: '#326dff',
        padding: '18px 4px',
        color: '#fff',

      },
      tagContainer: {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: 200,
          overflowY: 'auto',
          listStyle: 'none',
          '& > li': {
            position: 'relative',
          },
          '& > li > div > svg': {
            fill: 'rgba(255, 255, 255, .5)',
          },
          '& > li > hr': {
            top: '13px',
            right: '-20px',
            position: 'absolute',
            width: '20px',
            height: '1px',
            transform: 'rotate(90deg)',
          },
          '& > li > div': {
            margin: '4px 10px',
          },
          '& > li:last-child > hr': {
            display: 'none'
          },
          '&.MuiSvgIcon-root': {
            color: 'rgba(255, 255, 255, 0.75)'
          },
          [theme.breakpoints.down("600")]: {
            padding: '15px 10px',
            flexDirection: 'column',
            '& > li > hr': {
              display: 'none'
            },
            '& > li:last-child': {
              '& > div': {
                marginBottom: 0,
              },
              '& > hr': {
                display: 'none',
              },
            },
            '& > li > div': {
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              background: '#E0E9FF',
              borderRadius: '30px',
              color: '#326DFF',
              margin: 0,
              marginBottom: 10,
              '& > svg': {
                fill: 'white',
              },
              '& .MuiChip-label': {
                fontSize: 12,
                fontWeight: 500,
                paddingLeft: '15px',  
              },
              '&:hover': {
                background: '#326DFF',
                color: 'white',
              }
            }
          }
      },
      formContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
      },
      bt: {
          paddingLeft: 10,
          [theme.breakpoints.down("600")]: {
            paddingLeft: 0,
            marginTop: 80,
            width: '100%',
            textAlign: 'center',
          }
      },
      addToList: {
        marginTop: 16,
        marginLeft: 16,
        textTransform: 'initial',
        [theme.breakpoints.down("600")]: {
          margin: '0 auto',
          marginTop: 16,
        }
      },
      saveButton: {
          position: 'absolute',
          top: -48,
          right: 0,
          textTransform: 'initial',
          background: '#E9F1FC',
          color: '#326DFF',
          minWidth: 125,
          height: 36,
          margin: 0,
          [theme.breakpoints.down("600")]: {
            position: 'static',
          },
          '&:hover': {
            color: 'white',
            background: '#326DFF',
          }
      },
      hr: {
        border: '1px dashed rgba(71, 98, 130, .25)',
        boxSizing: 'border-box',
        marginLeft: '10px',
        marginRight: '10px',
      },
      input: {
        // [theme.breakpoints.down("600")]: {
          width: '328px',
          [theme.breakpoints.down("700")]: {
            width: '290px',
          },
          [theme.breakpoints.down("600")]: {
            width: '100%',
          },
          '& > .MuiInput-underline.Mui-error:after': {
            content: 'none',
          },
          '& .MuiInput-underline:before': {
            content: 'none',
          },
          '& .MuiInput-underline:after': {
            content: 'none',
          },
          '& > div.MuiInput-root': {
            height: 45,
            border: '1px solid #D6D6D6',
            padding: '10px 52px 10px 16px',
            borderRadius: 5,
            '& > input': {
              padding: 0,
              fontSize: 16,
              lineHeight: '24px',
              color: '#476282',
            },
          },
          '& > label.MuiInputLabel-root': {
            padding: '5px 52px 10px 16px',
          },
          '& > label.MuiInputLabel-shrink': {
            transform: 'translate(0, -3px) scale(0.75)',
          },
          '& > div.Mui-error': {
            border: '1px solid red',
          }
        },
        headerWrap: {
          display: 'flex',
          position: 'relative',
          width: 130,
        },
        popover: {
          position: 'relative',
          top: 6,
        }
      // }
}));

const afterSubmit = (result, dispatch) => 
    dispatch(reset('CategoriesForm'));
    
CategoriesForm = reduxForm({
    form: 'CategoriesForm', 
    onSubmitSuccess: afterSubmit,
  })(CategoriesForm);

  export default CategoriesForm;