import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

class Firebase {
	constructor(props) {
		let config = props.config;
		// console.log("config --> ", config);
		//let data = require(`./${login}.json`);
		// app.initializeApp(config);
		// this.auth = app.auth();
		// this.bd = app.database();
		// this.storage = app.storage();
		// this.deleteSessions = () => {
		// 	app.app().delete();
		// };

		firebase.initializeApp(config);
		this.auth = firebase.auth();
		this.bd = firebase.database();
		this.storage = firebase.storage();
		this.deleteSessions = () => {
			firebase.app().delete();
		};
	}

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignOut = () => {
		this.auth.signOut();
		this.deleteSessions();
	};
}

export default Firebase;
