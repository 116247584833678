export const validateImage = (imageFile) => {
	console.log("imageFile", imageFile);
	if (imageFile && imageFile.length > 0) {
		const types = ["image/jpeg", "image/png"];
		const imageFileKb = imageFile[0].size / 1024 / 1024;
		const imageType = types.includes(imageFile[0].type);

		if (imageFileKb > 0.5) {
			return "Розмір фото не більше 0.5 МБ";
		}

		if (!imageType) {
			return "Даний формат зображення не підтримується";
		}

		return undefined;
	} else {
		return "Це обов'язкове поле";
	}
};

export const size = (e) =>
	e
		? e.length > 0
			? e[0].size / 1024 / 1024 < 0.5
				? undefined
				: "Розмір фото не більше 0.5 МБ"
			: undefined
		: undefined;
export const trim = (str, count) =>
	str.length > count ? str.slice(0, count) + "…" : str;
