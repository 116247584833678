/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { withFirebase } from '../../../firebase';
import {isDirty, reduxForm} from 'redux-form';
import {updateVisibility, updateWorkhours, updateDefaultHours,  editShops} from '../../../reducers/shops';

import withWidth from '@material-ui/core/withWidth';

import {
  resetForms,
  checkSettingsDropdown,
} from '../other';

import Navigation from './navigation';
import useStyles from './styles';

let NavigationContainer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isOpenPush, setIsOpenPush] = useState(false);
  const [isOpenReviews, setIsOpenReviews] = useState(false);
  const [isOpenClients, setIsOpenClients] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenProducts, setIsOpenProducts] = useState(false);

  const [isDirtyPushForm, setIsDirtyPushForm] = useState(null);
  const [isDirtyCategoriesForm, setIsDirtyCategoriesForm] = useState(null);
  const [isDirtyProductsForm, setIsDirtyProductsForm] = useState(null);
  const [isDirtyCategoriesPromocodes, setIsDirtyCategoriesPromocodes] = useState(null);
  const [isDirtyProductsPromocodes, setIsDirtyProductsPromocodes] = useState(null);

  const [isHiddenCategories, setIsHiddenCategories] = useState(null);
  const [isHiddenProducts, setIsHiddenProducts] = useState(null);
  const [isDirtyHiddenCategories, setIsDirtyHiddenCategories] = useState(null);
  const [isDirtyHiddenProducts, setIsDirtyHiddenProducts] = useState(null);

  const [isHiddenCategoriesPromocodes, setIsHiddenCategoriesPromocodes] = useState(null);
  const [isHiddenProductsPromocodes, setIsHiddenProductsPromocodes] = useState(null);
  const [isDirtyHiddenCategoriesPromocodes, setIsDirtyHiddenCategoriesPromocodes] = useState(null);
  const [isDirtyHiddenProductsPromocodes, setIsDirtyHiddenProductsPromocodes] = useState(null);

  const isValidationMenu = isDirtyCategoriesForm || isDirtyProductsForm || isDirtyHiddenCategories || isDirtyHiddenProducts; 
  const isValidationPromocodes = isDirtyCategoriesPromocodes || isDirtyProductsPromocodes || isDirtyHiddenCategoriesPromocodes || isDirtyHiddenProductsPromocodes;
  const isValidDirtyWorktime = props.dirtyWorkTime || props.isDirtyWorkingHours;

  const handleClose = () => {
    setOpen(false);
    setIsSubmit(false);
    setIsOpenMenu(false);
    setIsOpenPush(false);
    setIsOpenReviews(false);
    setIsOpenClients(false);
    setIsOpenSettings(false);
  };

  const handleClickSubmit = () => {
    setOpen(false);
    setIsSubmit(true);
    checkSettingsDropdown(props);
    props.handleDrawerClose();

    resetForms(props.dispatch);
  };

  const menuCheck = isDirtyPushForm || isValidationPromocodes || isValidDirtyWorktime;
  const pushCheck = isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const reviewsCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const clientsCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const settingsCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const productsCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const promotionCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;
  const loadControlCheck = isDirtyPushForm || isValidationMenu || isValidationPromocodes || isValidDirtyWorktime;

  const ItemMenuClick = (check, path, name) => {
    const {history, handleOpen, handleProductOpen, handleDrawerClose} = props;
    if (check) {
      setOpen(true);

      if (name === 'menu') setIsOpenMenu(true);
      if (name === 'push') setIsOpenPush(true);
      if (name === 'reviews') setIsOpenReviews(true);
      if (name === 'clients') setIsOpenClients(true);
      if (name === 'settings') setIsOpenSettings(true);
      if (name === 'products') setIsOpenProducts(true);
      if (name === 'promotion') setIsOpenProducts(true);

    } else {
      if (path) {
        history.push(path);
        handleDrawerClose();

        if (path === '/page/menu/categories') {
          props.getDefaultHiddenCategories();
          props.getDefaultHiddenProducts();
        }
      } else {
        if (name === 'settings') handleOpen('settings');
        if (name === 'products') handleProductOpen('products');
      }
    }
  }

  useEffect(() => {
    let {handleDrawerClose, cityId} = props;

    if (cityId) {
      handleDrawerClose();
    }

  }, [props.location.pathname]);

  const handleMenuClick = () => ItemMenuClick(menuCheck, '/page/menu/categories', 'menu');
  const handlePushClick = () => ItemMenuClick(pushCheck, '/page/push', 'push');
  const handleReviewsClick = () => ItemMenuClick(reviewsCheck, '/page/reviews', 'reviews');
  const handleClientListClick = () => ItemMenuClick(clientsCheck, '/page/client-list', 'clients');
  const handleSettingsClick = () => ItemMenuClick(settingsCheck, null, 'settings');
  const handleProductsClick = () => ItemMenuClick(productsCheck, null, 'products');
  const handlePromotionClick = () => ItemMenuClick(promotionCheck, '/page/promotion', 'promotion');
  const handleLoadControlClick = () => ItemMenuClick(loadControlCheck, '/page/load-control', 'load-control');

  useEffect(() => {
    const {dirtyPushForm, dirtyCategoriesForm, dirtyProductsForm, hiddenCategories, hiddenProducts} = props;

    setIsDirtyPushForm(dirtyPushForm);
    setIsDirtyCategoriesForm(dirtyCategoriesForm);
    setIsDirtyProductsForm(dirtyProductsForm);

      if (hiddenCategories && hiddenCategories.length > 0) {
        if (!isHiddenCategories) {
          setIsHiddenCategories(hiddenCategories);
        }
      }

      if (hiddenProducts && hiddenProducts.length > 0) {
        if (!isHiddenProducts) {
          setIsHiddenProducts(hiddenProducts);
        }
      }

  }, [props, isHiddenCategories, isHiddenProducts]);

  useEffect(() => {
      const {dirtyCategoriesPromocodes, dirtyProductsPromocodes, hiddenCategoriesPromocodes, hiddenProductsPromocodes} = props;

      setIsDirtyCategoriesPromocodes(dirtyCategoriesPromocodes);
      setIsDirtyProductsPromocodes(dirtyProductsPromocodes);

      if (hiddenCategoriesPromocodes && hiddenCategoriesPromocodes.length > 0) {
        if (!isHiddenCategoriesPromocodes) {
          setIsHiddenCategoriesPromocodes(hiddenCategoriesPromocodes);
        }
      }

      if (hiddenProductsPromocodes && hiddenProductsPromocodes.length > 0) {
        if (!isHiddenProductsPromocodes) {
          setIsHiddenProductsPromocodes(hiddenProductsPromocodes);
        }
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHiddenCategoriesPromocodes, isHiddenProductsPromocodes, props.dirtyCategoriesPromocodes, props.dirtyProductsPromocodes, props.hiddenCategoriesPromocodes, props.hiddenProductsPromocodes]);

  useEffect(() => {
    const {hiddenCategories, hiddenProducts, categoriesMenu, productsMenu} = props;

    if (hiddenCategories && isHiddenCategories && categoriesMenu) {
      if (hiddenCategories.length === isHiddenCategories.length) {
        setIsDirtyHiddenCategories(false);
      } else {
        setIsDirtyHiddenCategories(true)
      }
    } else {
      setIsDirtyHiddenCategories(false);
    }

    if (hiddenProducts && isHiddenProducts && productsMenu) {
      if (hiddenProducts.length === isHiddenProducts.length) {
        setIsDirtyHiddenProducts(false);
      } else {
        setIsDirtyHiddenProducts(true);
      }
    } else {
      setIsDirtyHiddenProducts(false);
    }

  }, [isHiddenCategories, isHiddenProducts, isDirtyHiddenCategories, isDirtyHiddenProducts, props]);

  useEffect(() => {
    const {hiddenCategoriesPromocodes, hiddenProductsPromocodes, categoriesPromocode, productsPromocode} = props;

    if (hiddenCategoriesPromocodes && isHiddenCategoriesPromocodes && categoriesPromocode) {
      if (hiddenCategoriesPromocodes.length > 1 && isHiddenCategoriesPromocodes.length > 1) {
        if (hiddenCategoriesPromocodes.length === isHiddenCategoriesPromocodes.length) {
          setIsDirtyHiddenCategoriesPromocodes(false);
        } else {
          setIsDirtyHiddenCategoriesPromocodes(true)
        }
      } else if (hiddenCategoriesPromocodes.length === 1) {
        setIsDirtyHiddenCategoriesPromocodes(false);
      } else {
        setIsDirtyHiddenCategoriesPromocodes(true);
      }
    } else {
      setIsDirtyHiddenCategoriesPromocodes(false);
    }


    if (hiddenProductsPromocodes && isHiddenProductsPromocodes && productsPromocode) {
      if (hiddenProductsPromocodes.length > 1 && isHiddenProductsPromocodes.length > 1) {
        if (hiddenProductsPromocodes.length === isHiddenProductsPromocodes.length) {
          setIsDirtyHiddenProductsPromocodes(false);
        } else {
          setIsDirtyHiddenProductsPromocodes(true);
        }
      } else if (hiddenProductsPromocodes.length === 1) {
        setIsDirtyHiddenProductsPromocodes(false);
      } else {
        setIsDirtyHiddenProductsPromocodes(true);
      }

    } else {
      setIsDirtyHiddenProductsPromocodes(false);
    }

  }, [isHiddenCategoriesPromocodes, isHiddenProductsPromocodes, props]);

  useEffect(() => {
    const {
      cityId,
      handleOpen, 
      handleProductOpen,
      openCategory, 
      openProducts,
      history, 
      updateVisibility,
      editShops,

    } = props;

    const menuUrl = document.location.pathname === '/page/menu/categories';
    const discountUrl = document.location.pathname === '/page/discounts/' + props.cityId;

    const isMenu = menuUrl && (isValidationMenu && openCategory === 'settings');
    const isPush = document.location.pathname === '/page/push' && (isDirtyPushForm && openCategory === 'settings');
    const isWorkTime = document.location.pathname === '/page/worktime/' + cityId && (isValidDirtyWorktime && openCategory === 'settings');
    const isDiscount = discountUrl  && (isValidationPromocodes && openCategory === 'settings');
    const isProducts = menuUrl && (isValidationMenu && openProducts === 'products');

    if (isMenu || isProducts || isPush || isWorkTime || isDiscount) {
      handleOpen(null);
      handleProductOpen(null);
    }

    const clearFields = () => {
      if (menuUrl) {
        let {defaultHiddenCategories, defaultHiddenProducts} = props;
        // console.log('defaultHiddenCategories, --- >>>', props.defaultHiddenCategories);
        // console.log('defaultHiddenProducts, --- >>>', props.defaultHiddenProducts);
        updateVisibility({list: defaultHiddenCategories ? defaultHiddenCategories : null, params: 'hiddenCategories'});
        updateVisibility({list: defaultHiddenProducts ? defaultHiddenProducts : null, params: 'hiddenProducts'});
        setIsDirtyHiddenCategories(false);
        setIsDirtyHiddenProducts(false);
      }

      if (discountUrl) {
        let noDiscountForCategorieIds = props.defaultCity && props.defaultCity.noDiscountForCategorieIds;
        let noDiscountForProductIds = props.defaultCity && props.defaultCity.noDiscountForProductIds;
        // console.log('noDiscountForProductIds *', noDiscountForCategorieIds);
        // console.log('noDiscountForProductIds *', noDiscountForProductIds);
        editShops({key: cityId, value: noDiscountForCategorieIds ? noDiscountForCategorieIds : null, params: 'noDiscountForCategorieIds'});
        editShops({key: cityId, value: noDiscountForProductIds ? noDiscountForProductIds: null, params: 'noDiscountForProductIds'});
        setIsDirtyHiddenCategoriesPromocodes(false);
        setIsDirtyHiddenProductsPromocodes(false);
      }
    }

    if (isSubmit && isOpenMenu) {
      clearFields();
      setIsOpenMenu(false);
      setIsSubmit(false);
      history.push('/page/menu/categories');
    }

    if (isSubmit && isOpenPush) {
      clearFields();
      setIsOpenPush(false);
      setIsSubmit(false);
      history.push('/page/push');
    }

    if (isSubmit && isOpenReviews) {
      clearFields();
      setIsOpenReviews(false);
      setIsSubmit(false);
      history.push('/page/reviews');
    }

    if (isSubmit && isOpenClients) {
      clearFields();
      setIsOpenClients(false);
      setIsSubmit(false);
      history.push('/page/client-list');
    }

    if (isSubmit && isOpenSettings) {
      clearFields();
      // resetForms(dispatch);
      setIsOpenSettings(false);
      setIsSubmit(false);
    }

    if (isSubmit && isOpenProducts) {
      clearFields();
      setIsOpenProducts(false);
      setIsSubmit(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit, isOpenSettings, isOpenReviews, isOpenMenu, isDirtyPushForm, isValidationMenu, isValidDirtyWorktime, isValidationPromocodes, isOpenProducts]);
  let isMenu = document.location.pathname === '/page/menu/categories';
  let isPush = document.location.pathname === '/page/push';
  let isReviews = document.location.pathname === '/page/reviews';
  let isClientList = document.location.pathname === '/page/client-list';
  let isPromotion = document.location.pathname === '/page/promotion';
  let isLoadControl = document.location.pathname === '/page/load-control';
  let isCard = props.listCity[props.city] && props.listCity[props.city].card;

  let payments;
  let isValidFillPayCard = false;
  if (props.listCity[props.city]) {
    payments = props.listCity[props.city].cardPaymentSystem;

    for (let key in payments) {
      const item = payments[key];
      let arr = [];

      for (let nameField in item) arr.push(nameField);

      arr.length > 0 ?  isValidFillPayCard = true :  isValidFillPayCard = false;
    }
  }

  let payCard = props.adminPermission && props.adminPermission.payCard;
  // let isValidPayCard = isCard && isValidFillPayCard && payCard;
  let isValidPayCard = payCard;


  // Validation URL discounts
  function validPromocodesUrl(item) {
    let {adminPermission} = props;
    let discountsUrl = `/page/discounts/${item.key}`;
    let isValidDiscounts = props.adminPermission.saleFirstOrder || props.adminPermission.promoCodes || props.adminPermission.bannerControl;
  
    if (adminPermission) {
      let {saleFirstOrder, promoCodes, bannerControl} = adminPermission;
      
      if (!saleFirstOrder && !promoCodes) {
        discountsUrl = `/page/discounts/${item.key}/banners`;
      } else if (!saleFirstOrder) {
        discountsUrl = `/page/discounts/${item.key}/promoCodes`;
      } else {
        discountsUrl = `/page/discounts/${item.key}`;
      }
    }

    // console.log('discountsUrl --- .>>', discountsUrl);
    return discountsUrl;
  }

  return(
    <Navigation 
      modalOpen={open}
      isMenu={isMenu}
      isPush={isPush}
      isReviews={isReviews}
      isClientList={isClientList}
      isPromotion={isPromotion}
      isLoadControl={isLoadControl}
      isValidPayCard={isValidPayCard}
      handleClose={handleClose}
      handleClickSubmit={handleClickSubmit}
      handleMenuClick={handleMenuClick}
      handlePushClick={handlePushClick}
      handleReviewsClick={handleReviewsClick}
      handleClientListClick={handleClientListClick}
      handlePromotionClick={handlePromotionClick}
      handleLoadControlClick={handleLoadControlClick}
      handleSettingsClick={handleSettingsClick}
      validPromocodesUrl={validPromocodesUrl}
      handleProductsClick={handleProductsClick}
      {...props}
    />
  )
}

NavigationContainer = reduxForm({ form: 'PushForm'})(connect(
  state => ({
    dirtyPushForm: isDirty('PushForm')(state),
    dirtyCategoriesForm: isDirty('CategoriesForm')(state),
    dirtyProductsForm: isDirty('ProductsForm')(state),
    dirtyCategoriesPromocodes: isDirty('AddCategories')(state),
    dirtyProductsPromocodes: isDirty('AddProducts')(state),
    dirtyWorkTime: isDirty('TableForm')(state),
  })
)(NavigationContainer));

const mapStateToProps = (state, props) => {
  let location = props.location.pathname.split('/');
  let id = location[location.length - 1];
  let item = state.shops.shops[id];

  return {
    hiddenCategories: state.shops.hiddenCategories,
    hiddenProducts: state.shops.hiddenProducts,
    hiddenCategoriesPromocodes: item ? item.noDiscountForCategorieIds : null,
    hiddenProductsPromocodes: item ? item.noDiscountForProductIds : null,
    cityId: id,
  }
}

export default withWidth()(withRouter(withFirebase(connect(mapStateToProps, {updateVisibility, editShops, updateWorkhours, updateDefaultHours})(NavigationContainer))));
