import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Input, File, Textarea, Select } from "../../ui/Input/";
import s from "./products.module.css";
import { reduxForm, reset, Field, isValid } from "redux-form";
import { required } from "redux-form-validators";
import { connect } from "react-redux";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";

const size = (e) =>
	e
		? e.length > 0
			? e[0].size / 1024 / 1024 < 0.5
				? undefined
				: "Размер фото не более 0.5 МБ"
			: undefined
		: undefined;

let EditForm = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(null);
	const [isOpenCities, setIsOpenCities] = useState(null);
	let names = props.product.name
		? props.product.name
		: { [props.defaultLanguage]: "" };
	let descriptions = props.product.description
		? props.product.description
		: { [props.defaultLanguage]: "" };
	let data = props.languages;
	let namesInitial = {};
	let descriptionsInitial = {};

	data.map((item) => {
		let name = "name" + "_" + item.key;
		let description = "description" + "_" + item.key;

		namesInitial = {
			...namesInitial,
			[name]: names[item.key] ? names[item.key] : null,
		};

		descriptionsInitial = {
			...descriptionsInitial,
			[description]: descriptions[item.key] ? descriptions[item.key] : null,
		};
	});

	const [tabActive, setTabActive] = useState(0);

	useEffect(() => {
		props.initialize({
			...namesInitial,
			...descriptionsInitial,
			categories: props.product.menuCategoryId,
			firstPrice: Number(props.product.spots[0].price) / 100,
		});
	}, []);

	// const handleTabClick = (count) => {
	//     // console.log('count', count);

	//     setTabActive(count);
	//     // props.updateLanguage(language);
	// }

	const handleTabClick = (count) => {
		const defaultLanguage = props.language;
		const languages = props.languages;
		let title = languages
			.find((item) => item.key === defaultLanguage)
			.label.split(" ");
		title = title[title.length - 1];
		if (count === 0) {
			setTabActive(count);
		}

		// console.log('count, isValid', count, isValid);

		if (isValid) {
			setTabActive(count);
		} else {
			if (count !== 0) {
				alert(`${t("products.firstlyAddProduct")} ${title}`);
			}
		}
	};

	useEffect(() => {
		setIsValid(props.valid);
	}, [props.valid]);

	const trim = (str, count) =>
		str.length > 7
			? str.replace("м. ", "").slice(0, 5)
			: str.replace("м. ", "");

	const handleCheckboxChange = () => {
		console.log("=== change ===");
	};

	const handleOpenCitys = () => {
		setIsOpenCities(!isOpenCities);
	};

	//   console.log('props citys, product: ', props.citys, props.product);

	return (
		<div className={s.modal}>
			<div className={s.form}>
				<form
					className={s.formContainer}
					autoComplete="off"
					onSubmit={props.handleSubmit}
				>
					<div
						className={s.close}
						onClick={() => props.statusEditModal(null)}
					></div>
					<span className={s.title}></span>
					<Field
						name={"categories"}
						option={props.categories}
						component={Select}
						label={"Категория*"}
						validate={required({ msg: "Это поле обязательно для заполнения" })}
					/>

					<div
						className={classes.citys}
						style={{
							maxHeight: isOpenCities ? "100%" : "75px",
						}}
					>
						{props.citys.map((item) => {
							return (
								<Tooltip title={item.address}>
									<FormControlLabel
										control={
											<Checkbox
												checked={true}
												onChange={handleCheckboxChange}
											/>
										}
										label={trim(item.address)}
										className={s.citysLabel}
									/>
								</Tooltip>
							);
						})}
					</div>
					<div className={classes.button} onClick={handleOpenCitys}>
						{isOpenCities ? "Скрыть" : "Показать больше"}
					</div>

					<div className={classes.tabs}>
						{data.map((item, index) => {
							let name = item.label.split(" ");
							name = name[name.length - 1];
							name = name.replace("ом", "ий");

							return (
								<div
									style={{
										background: tabActive === index ? "#255ce5" : null,
									}}
									onClick={() => handleTabClick(index)}
									className={classes.tab}
								>
									{name}
								</div>
							);
						})}
					</div>
					{data.map((item, index) => {
						let name = "name" + "_" + item.key;
						let description = "description" + "_" + item.key;

						return (
							<div
								style={{
									display: tabActive === index ? "block" : "none",
								}}
							>
								<Field
									name={name}
									component={Input}
									label={"Название*"}
									validate={
										index === 0 &&
										required({ msg: "Это поле обязательно для заполнения" })
									}
								/>
								<Field
									className={classes.textarea}
									name={description}
									component={Textarea}
									label={"Описание"}
								/>
							</div>
						);
					})}
					<Field
						name={`firstPrice`}
						component={Input}
						label={"Цена*"}
						validate={required({ msg: "Это поле обязательно для заполнения" })}
						type={"number"}
					/>
					<Field
						name={`photo`}
						component={File}
						label={"Новое фото"}
						validate={[size]}
						type={"file"}
					/>
					<button type="submit">Сохранить</button>
				</form>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	tabs: {
		display: "flex",
		marginBottom: "10px",
		flexWrap: "wrap",
	},
	tab: {
		fontSize: 13,
		margin: "0 3px 5px 3px",
		padding: "7px 12px",
		background: "#326dff",
		borderRadius: "5px",
		cursor: "pointer",
		color: "white",
		"&:hover": {
			background: "rgba(50, 109, 255, .75)",
		},
	},
	tabsContent: {
		marginTop: 20,
	},
	textarea: {
		fontFamily: "Roboto, sans-serif",
	},
	citys: {
		maxHeight: 75,
		overflow: "hidden",
	},
	citysLabel: {
		position: "relative",
		"&::before": {
			content: "1",
			position: "absolute",
			top: 0,
			left: 0,
			width: 10,
			height: 20,
			background: "red",
		},
	},
	button: {
		width: "100%",
		maxWidth: "250px",
		height: "40px",
		display: "flex",
		alignItems: "center",
		textTransform: "none",
		justifyContent: "center",
		background: "#326dff",
		margin: "16px auto",
		borderRadius: "5px",
		fontSize: "16px",
		color: "#fff",
		cursor: "pointer",
		border: "none",
		"&:hover": {
			background: "rgba(50, 109, 255, .75)",
		},
	},
}));

const afterSubmit = (result, dispatch) => dispatch(reset("EditForm"));

EditForm = reduxForm({
	form: "EditForm",
	onSubmitSuccess: afterSubmit,
})(EditForm);

EditForm = connect((state) => ({
	valid: isValid("ProductsForm")(state),
}))(EditForm);

export default EditForm;
