import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { withFirebase } from '../../../../firebase';
import { CSVLink, CSVDownload } from "react-csv";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkedAllTableInput, updateSelectType, checkedAllClients } from '../../../../reducers/clientTable';
import FilterListIcon from '@material-ui/icons/FilterList';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '../Select';
import SelectList from '../SelectPageView';
import SelectMultiline from '../SelectMultiline';
import Search from '../Search';
import ModalForm from './ModalForm';
import Loading from '../../../../components/Loading';

import useStyles from './style';

const columnsOptions = ['Имя', 'Телефон', 'Город', 'Дата установки', 'Пол', 'Скидка', 'Платформа'];
const dropListOption = [{id: 1, name: 'по имени'},{id: 2, name: 'по телефону'}, {id: 3, name: 'по городу'}];

const Navigation = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const downloadLink = useRef(null);
    // const columnsOptions = useSelector(state => state.clientTable.columns);
    const selectType = useSelector(state => state.clientTable.selectType);
    const table = useSelector(state => state.clientTable.table);
    const allClients = useSelector(state => state.clientTable.allClients);
    const notificationKey = useSelector(state => state.shops.appSettings.notificationKey);
    const bundleId = useSelector(state => state.shops.appSettings.bundleId);

    const [loadStatus, setLoadStatus] = useState(false);
    const [alertText, setAlertText] = useState(null);
    const [alertShow, setAlertShow] = useState(null);

    const [count, setCount] = useState(0);
    const [action, setAction] = useState(0);
    const [columns, setColumns] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [csvreport, setCsvreport] = useState(null);
    // const [selectValue, setSelectValue] = useState(1);

    useEffect(() => {
        // getProductPushKey();
    }, []);

    useEffect(() => {
        let counts = 0;

        allClients.forEach(t => {
            if (t.checked) {
                counts += 1;
            }
        });

        setCount(counts);
    }, [allClients]);

    useEffect(() => {
        if (downloadLink.current && csvreport) {
            setTimeout(() => {
                downloadLink.current.link.click();
            }, 1000);
        }
    }, [csvreport, downloadLink]);

    const makeCSVDate = () => {
        const headers = [
            { label: "Имя", key: "name" },
            { label: "Телефон", key: "phone" },
            { label: "Город", key: "city" },
            { label: "Дата установки", key: "installDate" },
            { label: "Пол", key: "gender" },
            { label: "Скидка", key: "discount" },
            { label: "Платформа", key: "platform" },
        ];

        let data = [];

        allClients.forEach(t => {

            if (t.checked) {
                let item = {
                    name: t.name,
                    phone: t.phone,
                    city: t.city,
                    birthday: t.birthday,
                    gender: t.gender,
                    discount: t.discount,
                    platform: t.platform,
                }

                data.push(item);
            }

        });

        const csvReportData = {
            data,
            headers: headers,
            filename: 'List_of_clients.csv'
        }

        setCsvreport(csvReportData);
    }

    const handleAction = (event) => {
        const value = event.target.value;

        if (value === 1)  makeCSVDate();
        if (value === 2) setOpenModal(true);
    };

    const clearSelectedClients = () => {
        dispatch(checkedAllTableInput(false));
        props.setAllCheckBox(false);
    }

    const selectAllClients = () => {
        dispatch(checkedAllTableInput(true));
    }

    const handleSelectList = (e) => {
        dispatch(updateSelectType(e.target.value));
    }

    const handleSubmit = (data) => {
        console.log('--- onSubmit ---', data);

        let topic = '';
        let keyPush = notificationKey;
        const selectedItems = table.filter(t => t.checked);

        let notification = {
            body: data.text,
        }

        selectedItems.forEach(t => {
            topic += `'${t.phone.replace(/-/g, '')}' in topics || `;
        });

        topic = topic.slice(0, topic.length - 3);

        let body = {
            condition: topic,
            notification,
        }

        // if (product) {
        //     body = {
        //         ...body,
        //         notification: {
        //             ...notification,
        //             click_action: `${bundleId}.FIREBASE_DATA`,
        //             title: '',
        //         },
        //         data: {
        //             product_id: product,
        //             body: '',
        //             title: '',
        //         }
        //     }
        // }

        console.log('body --- >>>', body);
        console.log('body --- >>>', JSON.stringify(body));
        console.log('Authorization', 'key=' + keyPush);

        setLoadStatus(true);
        fetch('https://fcm.googleapis.com/fcm/send', {
            'method': 'POST',
            'headers': {
                'Authorization': 'key=' + keyPush,
                'Content-Type': 'application/json'
         },
            'body': JSON.stringify(body)
        }).then(function(response) {
            console.log('--- PUSH response----', response);

            if (response.status == 200) {
                setAlertText(t('push.success'));
                setAlertShow(true);
                setLoadStatus(false);
                setOpenModal(false);
            }
        }).catch(function(error) {
            setAlertText(t('push.error'));
            setAlertShow(true);
            setLoadStatus(false);
            setOpenModal(false);
        });
    }

    return (
        <>
            {loadStatus && <Loading />}
            {csvreport && <CSVLink ref={downloadLink} {...csvreport}></CSVLink >}
            <ModalForm 
                table={allClients ? allClients : []}
                open={openModal} close={() => setOpenModal(false)} 
                onSubmit={handleSubmit} 
            />
            <div className={classes.root}>
                <div className={classes.firstWrap}>
                    <Select
                        name="action"
                        label="Действия"
                        stateValue={action} 
                        handleChange={handleAction}
                    >
                        <MenuItem value={1}><div  className={classes.firstInput}>Экспортировать</div ></MenuItem>
                        <MenuItem value={2}><div className={classes.firstInput}>Отправить Push уведомление</div></MenuItem>
                    </Select>
                </div>
                <div className={classes.lastWrap}>
                    <div className={classes.searchWrapper}>
                        {/* <SelectList 
                            className={classes.dropSelectList}
                            name="dropList"
                            options={dropListOption}
                            stateValue={selectType} 
                            handleChange={handleSelectList}
                        /> */}
                        <Search
                            getFindToSearch={props.getFindToSearch} 
                            getClientsToFirebase={props.getClientsToFirebase} 
                        />
                    </div>
                    <SelectMultiline
                        className={classes.multilineSelect}
                        name="columns" 
                        label="Столбцы"
                        options={columnsOptions}
                        stateValue={columns} 
                        // handleChange={handleColumns} 
                    />
                </div>
            </div>
            {count > 0 && (
                <div className={classes.alertMessage}>
                    <Alert className={classes.alert} severity="success">
                        <span>Выбрано клиентов ({count}) из {allClients.length}. </span> 
                        <Link 
                            onClick={() => selectAllClients()} 
                            className={classes.link} href="#">
                                Выбрать всех клиентов
                        </Link>
                        <Link 
                            onClick={() => clearSelectedClients()} 
                            className={classes.link} href="#">
                                Отменить выделение
                        </Link>
                    </Alert>
                </div>
            )}
        </>
    )
}

export default withFirebase(Navigation);
