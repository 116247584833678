import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import s from "./login.module.css";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { required } from "redux-form-validators";

const LoginForm = (props) => {
	const { handleSubmit } = props;
	const { t } = useTranslation();

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className={s.title}>E-APP</div>
			<Field
				name="login"
				component={CustomizedInputs}
				type="text"
				placeholder={t("loginScreen.yourRestaurant")}
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: t("loginScreen.requiredField") })}
			/>

			<Button
				text={
					props.loadStatus ? (
						<Loading size={22} color={"#fff"} />
					) : (
						t("loginScreen.continue")
					)
				}
				disabled={props.loadStatus ? true : false}
				handleClick={handleSubmit}
			/>
		</form>
	);
};

export default reduxForm({
	form: "LoginForm",
})(LoginForm);
