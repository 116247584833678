import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles({
  select: {
    marginRight: '8px',
    color: '#326Dff',
  },
});

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();

    const handleChangeLanguage = (event) => {
      i18n.changeLanguage(event.target.value);
    };

    return( 
        <Select
            value={i18n.language}
            onChange={handleChangeLanguage}
            className={classes.select}
            disableUnderline 
        >
            <MenuItem value="uk">UA</MenuItem>
            <MenuItem value="ru">RU</MenuItem>
        </Select>
    )
}

export default LanguageSelector;