import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";

import s from "./login.module.css";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { required } from "redux-form-validators";

let AuthForm = (props) => {
	const { handleSubmit, onReturn } = props;
	const { t } = useTranslation();

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className={s.title}>E-APP</div>
			<Field
				name="email"
				component={CustomizedInputs}
				type="email"
				placeholder={t("loginScreen.email")}
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: t("loginScreen.requiredField") })}
			/>

			<Field
				name="password"
				component={CustomizedInputs}
				type="password"
				placeholder={t("loginScreen.password")}
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: t("loginScreen.requiredField") })}
			/>

			<div className={s.buttonsWrap}>
				<Button
					text={
						props.loadStatus ? (
							<Loading size={22} color={"#fff"} />
						) : (
							t("loginScreen.logIn")
						)
					}
					disabled={props.loadStatus ? true : false}
					handleClick={handleSubmit}
				/>
				<Button handleClick={onReturn} text={t("loginScreen.back")} />
			</div>
		</form>
	);
};

AuthForm = reduxForm({
	form: "AuthForm",
})(AuthForm);

export default AuthForm;
