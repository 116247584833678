/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import disableApp from '../../assets/img/disableApp.png';
import AlertForm from './alertForm';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../../components/Loading';

const CustomSwitch = withStyles({
    switchBase: {
      color: '#326dff',
      '&$checked': {
        color: '#326dff',
      },
      '&$checked + $track': {
        backgroundColor: '#326dff',
      },
    },
    checked: {},
    track: {},
  })(Switch);

const DisableApp = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
            <Typography variant="h6" className={classes.title}>
                 {t('disableApp.status')}
            </Typography>
                {props.shops ? <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                                checked={props.shops.orderAvailable}
                                onChange={() => props.updateStatusApp()}
                                value={props.shops.orderAvailable}
                            />
                        }
                        label={t('disableApp.turnOnOrders')}
                    />
                 </FormGroup> : <Loading size={22} color={'#326dff'}/>}
                <AlertForm alert={props.alert} onSubmit={props.updateAlert}/>
            </Grid>
            <Grid item xs={12} md={5}>
                <div className={classes.imgContainer}>
                    <img className={classes.disableApp} src={disableApp}/>
                </div>
            </Grid>
        </Grid>
    )
} 
const useStyles = makeStyles(theme => ({
    imgContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    disableApp: {
        width: '100%',
        maxWidth: 300,
        height: 'auto',
        margin: 'auto'
    },
    title: {
        marginBottom: 10,
        fontSize: 18
      },
}))

export default DisableApp;