import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

import DefaultScreen from "./defaultScreen";
import { AuthUserContext, withAuthorization } from "../../sessions";

const DefaultScreenContainer = (props) => {
	const [isDebug, setIsDebug] = useState(false);
	// let isDebug;
	let debug;
	const getVersion = () => {
		props.firebase.bd.ref("adminPermission/isDebug").on("value", (snapshot) => {
			debug = snapshot.val();
			setIsDebug(debug);
			localStorage.setItem("isDebug", debug ? debug : false);
		});
	};

	console.log("isDebug", JSON.parse(localStorage.getItem("isDebug")));

	useEffect(() => {
		getVersion();
		// console.log("isDebug", isDebug);
	}, [isDebug]);

	return (
		<AuthUserContext.Consumer>
			{(authUser) => <DefaultScreen isDebug={isDebug} />}
		</AuthUserContext.Consumer>
	);
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(DefaultScreenContainer);
