import React from 'react';
import { useTranslation } from "react-i18next"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm} from 'redux-form';
import {required} from 'redux-form-validators';
import CustomizedInputs from '../../../components/UIform';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import Chip from '@material-ui/core/Chip';
import Popover from '../../../components/Popover';

import { Typography } from '@material-ui/core';

let Categories = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');
    let { handleSubmit, noDiscountForCategorieIds, editShops } = props;

    if (!noDiscountForCategorieIds || !noDiscountForCategorieIds[0]) noDiscountForCategorieIds = [];

    const handleDelete = (chipToDelete) => () => {
        let list = props.noDiscountForCategorieIds.filter((chip) => chip !== chipToDelete);
        props.editShops(list, 'noDiscountForCategorieIds');
      };

      const addChip = (data) => {
        let status = noDiscountForCategorieIds.find((chip) => chip === data.idCategories);
        let isValue;

        if (Object.keys(data).length == 0) {
            isValue = false;
        } else {
            isValue = true;
        }

        if(status === undefined && isValue){
            let list = [...noDiscountForCategorieIds, data.idCategories];
            editShops(list, 'noDiscountForCategorieIds');
            props.reset();
        } else if (!isValue) {
            console.log('Пустое поле');
        } else {
            alert(t('menu.duplicateId'));
        }
      };

    return (
        <div className={classes.categoriesWrap}>
            <div className={classes.headerWrapper}>
                <Typography className={classes.typography}>{t('sales.noSaleCategories')}</Typography>
                <Popover className={classes.popover} title="" hiddenTitle="-" />
            </div>
            <div className={classes.chips}>
                <ul className={classes.chipsList}>
                    {noDiscountForCategorieIds.map((data, index) => {
                        return (
                            <li key={index} className={classes.chipsListLi}>
                                <Chip
                                    label={data}
                                    onDelete={handleDelete(data)}
                                    className={classes.chip}
                                />
                                 {isMin600 && <hr className={classes.hr} />}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <form onSubmit={handleSubmit} className={classes.categoriesContent} autoComplete='off'>
                <div className={classes.wrapper}>
                    <Field name="idCategories"
                        component={CustomizedInputs}
                        className={classes.inputField}
                        placeholder={t('menu.enterCategory')}
                        label=""
                        rows={'1'}
                        disabled={props.loadStatus ? true : false}
                        validate={required({msg: t('modals.requiredField')})} />

                    <div>
                        <Button className={classes.button} text={props.loadStatus ? 
                            <Loading size={22} color={'#fff'}/> : t('menu.addToList')} 
                                        disabled={props.loadStatus ? true : false}
                                        handleClick={handleSubmit(addChip)}/>
                    </div>
                </div>
           
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    headerWrapper: {
        display: 'flex',
        position: 'relative',
        maxWidth: 440,
    },
    popover: {
        top: 2,
    },
    categoriesWrap: {
        marginTop: 24,
        padding: 16,
        minHeight: 132,

        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('600')]: {
            backgroundColor: 'white',
        },
    },
    typography: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '21px',
        paddingRight: 20,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'end',
        [theme.breakpoints.down('600')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    categoriesContent: {
        display: 'flex',
        [theme.breakpoints.down('600')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    inputField: {
        '& input': {
            width: 328,
            height: 45,
            border: '1px solid #DADCE0',
            borderRadius: '5px',
            padding: '11px 50px 11px 16px',
            boxSizing: 'border-box',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none',
        },
        '& .MuiInput-underline:after': {
            content: 'none',
        },
        '& .MuiInput-underline:before': {
            content: 'none',
        },
        '& .Mui-error input': {
            border: '1px solid red',
        },
        [theme.breakpoints.down('375')]: {
            '& > div > input': {
                width: '100%',
            }
        },
    },
    button: {
        marginTop: 0,
        marginLeft: 15,
        fontSize: 14,
        textTransform: 'inherit',
        [theme.breakpoints.down('600')]: {
            marginTop: 16,
            marginLeft: 0,
        },
    },

    chipsList: {
        paddingLeft: 0,
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('600')]: {
            width: '100%',
            flexDirection: 'column',
            '& > li > div': {
                width: '100%',
            },
            '& > li:last-child > hr': {
                display: 'none',
            }
        }

    },
    chipsListLi: {
        listStyleType: 'none',
        marginRight: 5,
        marginBottom: 5,
        [theme.breakpoints.down('600')]: {
            marginRight: 5,
        }
        
    },
    chips: {
        
    },
    chip: {
        fontSize: 12,
        lineHeight: 1,
        color: 'white',
        backgroundColor: '#326DFF',
        [theme.breakpoints.down('600')]: {
            display: 'flex',
            justifyContent: 'space-between',
            background: '#E0E9FF',
            color: '#326DFF',
            padding: '0 6px',
        },
        
        '&.MuiChip-deletable > .MuiChip-deleteIcon': {
            color: 'rgba(255, 255, 255, .26)',
            [theme.breakpoints.down('600')]: {
                color: 'rgba(255, 255, 255, 1)',
            },
        }
    },
    hr: {
        border: '1px dashed rgba(71, 98, 130, .25)',
        boxSizing: 'border-box',
        marginLeft: '10px',
        marginRight: '10px',
    },
}))


Categories = reduxForm({
    form: 'AddCategories', 
    enableReinitialize : true
})(Categories);

export default Categories;