import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { withFirebase } from "../../../../firebase";
import {
	updateCategories,
	updateProduct,
	editProduct,
	updateLanguages,
} from "../../../../reducers/shops";
import { withRouter } from "react-router-dom";

import EditProduct from "./editProduct";
import Loading from "../../../../components/Loading";

const EditProductContainer = (props) => {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth.login);
	// const cities = useSelector(state => state.shops.cities);
	const categories = useSelector((state) => state.shops.categories);
	// const products = useSelector(state => state.shops.products);
	const defaultLanguage = useSelector((state) => state.shops.defaultLanguage);
	// const languages = useSelector(state => state.shops.languages);
	const cityId = props.match.params.id;

	const [preload, setPreload] = useState(false);
	const [products, setProducts] = useState(null);
	const [cities, setCities] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [product, setProduct] = useState(null);
	const [citiesSpot, setCitiesSpot] = useState([]);
	const [sortLanguages, setSortLanguages] = useState(null);
	const [deliveryTerminals, setDeliveryTerminals] = useState(null);
	const [deliveryTerminalsCheckBox, setDeliveryTerminalsCheckBox] =
		useState(null);
	const [isOpenCities, setIsOpenCities] = useState(true);

	useEffect(() => {
		getLanguages();
	}, []);

	useEffect(() => {
		if (languages) {
			sortedLanguages();
		}
	}, [languages]);

	useEffect(() => {
		if (!product && products) {
			getProduct();
		}

		if (!products) {
			getProducts();
		}
	}, [products]);

	useEffect(() => {
		if (!cities) {
			getCities();
		}
	}, [cities]);

	const getProducts = () => {
		props.firebase.bd
			.ref(
				`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/products`
			)
			.once("value", (snapshot) => {
				let products = snapshot.val();

				if (products) setProducts(products);
			});
	};

	const getProduct = () => {
		for (let id in products) {
			if (id === cityId) {
				setProduct(products[cityId]);
			}
		}
	};

	const getLanguages = () => {
		props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/languages`
			)
			.once("value", (snapshot) => {
				let languages = snapshot.val();

				setLanguages(languages);
			});
	};

	const sortedLanguages = () => {
		languages.sort((a, b) => {
			if (a.key !== defaultLanguage) {
				return 1;
			} else {
				return -1;
			}
		});

		setSortLanguages(languages);
	};

	const getCities = () => {
		props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City`
			)
			.once("value", (snapshot) => {
				let data = snapshot.val();

				if (data) setCities(data);
			});
	};

	const handleDeliveryTerminalsCheckBox = (data) =>
		setDeliveryTerminalsCheckBox(data);
	const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
	const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

	const createProduct = (data) => {
		console.log("createProduct");

		let languagesName = {};
		let languagesDescription = {};

		for (let index in languages) {
			let key = languages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		let spots = [];
		let allTerminals = [];

		cities.map((city) => allTerminals.push(...city.deliveryTerminals));

		allTerminals.map((terminal, index) => {
			let spot = {
				price: "0",
				spot_id: terminal.id,
				visible: "0",
			};
			spots[index] = spot;
		});

		for (let key in spots) {
			const terminal = spots[key];
			citiesSpot.map(({ id, visible }) => {
				if (String(terminal.spot_id) === String(id)) {
					console.log("terminal", terminal);

					let spot = {
						price:
							data.firstPrice && !isOpenCities
								? String(Number(data.firstPrice * 100))
								: String(Math.round(+data.terminals[id] * 100)),
						oldPrice: data.oldPrice
							? String(Math.round(data.oldPrice * 100))
							: "",
						spot_id: id,
						visible:
							(cities.length === 1 && citiesSpot.length === 1) ||
							(data.firstPrice && !isOpenCities)
								? "1"
								: visible,
					};

					spots[key] = spot;
				}
			});
		}

		setPreload(true);
		let product = {
			menuCategoryId: data.categories,
			name: languagesName,
			description: languagesDescription,
			spots,
		};

		return product;
	};

	const onSubmit = (data) => {
		console.log("data --- ==>>> ", data);
		let product = createProduct(data);
		console.log("product", product);

		if (login === "osama-sushi") {
			props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/updates`
				)
				.update({ lastUpdateInMenu: Date.now() });
		}

		if (data.photo) {
			let file = new FormData();
			file.append("photo", data.photo[0]);
			file.append("login", login);
			axios
				.post(`https://e-admin.com.ua/photo/index.php`, file)
				.then((res) => {
					const namePhoto = res.data.image_name.split(" ")[0];
					const isSuccess = res.data.check_upload_image;
					// console.log('res ---- >>> ', res);
					// console.log('product ---- >>> ', product);

					product.photo = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;
					product.namePhoto = namePhoto;

					if (isSuccess) {
						props.firebase.bd
							.ref(
								`${
									JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
								}/products/${cityId}`
							)
							.update(product);
						// alert('Готово с изображением!')
						setPreload(false);
						setTimeout(
							() =>
								props.history.push(
									`/page/menu/products/edit/category${data.categories}`
								),
							300
						);
					} else {
						alert("Ошибка, попробуйте ещё раз!");
						setPreload(false);
					}
				})
				.catch((res) => {
					alert("Ошибка, попробуйте ещё раз!");
					setPreload(false);
				});
		} else {
			// console.log('product', product)

			props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/products/${cityId}`
				)
				.update(product)
				.then((res) => {
					let editProductData = { id: props.match.params.id, product };
					// console.log('editProductData', editProductData);
					editProduct(dispatch, editProductData);
					setPreload(false);
					setTimeout(
						() =>
							props.history.push(
								`/page/menu/products/edit/category${data.categories}`
							),
						300
					);
				})
				.catch((error) => {
					setPreload(false);
					console.error("error", error);
				});
		}
	};

	// console.log('props', props);

	return product ? (
		<EditProduct
			product={product}
			onSubmit={onSubmit}
			handleCheckBox={handleDeliveryTerminalsCheckBox}
			// deliveryTerminals={this.state.deliveryTerminals}
			handleChangeCitiesSpot={handleChangeCitiesSpot}
			handleIsOpenCities={handleIsOpenCities}
			isOpenCities={isOpenCities}
			cityId={cityId}
			cities={cities}
			languages={sortLanguages}
			defaultLanguage={defaultLanguage}
			categories={categories}
			products={products}
		/>
	) : (
		<Loading />
	);
};

export default withFirebase(withRouter(EditProductContainer));
