import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../firebase";
import { updateFirebaseConfig, updateLogin } from "../../reducers/auth";
import Auth from "./auth";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../../sessions";

class AuthContainer extends Component {
	constructor() {
		super();
		this.state = {
			loadStatus: false,
		};
	}
	signIn = (data) => {
		this.setState({
			loadStatus: this.state.loadStatus ? false : true,
		});
		this.props.firebase
			.doSignInWithEmailAndPassword(data.email, data.password)
			.then((res) => {
				let login = localStorage.getItem("@login");

				if (login) {
					this.props.updateLogin(login);
				}

				// console.log( 'res signIn', res );
				this.props.history.push(ROUTES.DEFAULT);
				this.setState({ loadStatus: false });
			})
			.catch((error) => {
				alert("Ошибка! Попробуйте еще раз!");
				this.setState({ loadStatus: false });
			});
	};

	signOut = () => {
		this.props.history.push(ROUTES.SIGN_IN);

		localStorage.clear("@login");
		this.props.updateFirebaseConfig(null);
		this.props.updateLogin(null);
		this.props.firebase.doSignOut();
	};

	render() {
		console.log("authUser", this.props);
		return (
			<AuthUserContext.Consumer>
				{(authUser) =>
					authUser ? (
						this.props.history.push(ROUTES.DEFAULT)
					) : (
						<Auth
							onSubmit={this.signIn}
							loadStatus={this.state.loadStatus}
							onReturn={this.signOut}
						/>
					)
				}
			</AuthUserContext.Consumer>
		);
	}
}

const AuthNext = compose(withFirebase, withRouter)(AuthContainer);

export default connect(null, { updateFirebaseConfig, updateLogin })(AuthNext);
