import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationUK from "./locales/uk.json";
import translationRU from "./locales/ru.json";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources: {
			// Здесь вы можете добавить ресурсы с переводами на разные языки
			ru: {
				translation: translationRU,
			},
			uk: {
				translation: translationUK,
			},
		},
		lng: "uk", // Установите язык по умолчанию
		fallbackLng: "uk", // Установите резервный язык, если перевод отсутствует
		interpolation: {
			escapeValue: false, // Включите обработку HTML-тегов в переводах
		},
	});

export default i18n;
