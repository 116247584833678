import React, { Component } from "react";

import RenderFirebase from "./RenderFirebase";
import Login from "./scenes/login/loginContainer";
import { connect } from "react-redux";
import { updateFirebaseConfig, updateLogin } from "./reducers/auth";
import { firebaseAPI } from "./api";

class App extends Component {
	constructor() {
		super();
		this.state = {
			status: false,
			login: "",
		};
	}

	componentDidMount() {
		let login = localStorage.getItem("@login");
		if (login) {
			if (login === "osama-sushi") {
				alert("Перейдите в новую админку https://admin-osama.fun");
			} else {
				firebaseAPI
					.getConfig(login)
					.then((res) => {
						if (res.error) {
						} else {
							this.props.updateFirebaseConfig(res);
							this.props.updateLogin(login);
						}
					})
					.catch((res) => {});
			}
		}
	}

	updateFirebaseConfig = (config) => {
		this.props.updateFirebaseConfig(config);
	};

	render() {
		return (
			<>
				{this.props.firebaseConfig ? (
					<RenderFirebase config={this.props.firebaseConfig} />
				) : (
					<Login updateFirebaseConfig={this.updateFirebaseConfig} />
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	console.log("state --->>>", state);
	return {
		firebaseConfig: state.auth.firebaseConfig,
	};
};

export default connect(mapStateToProps, { updateFirebaseConfig, updateLogin })(
	App
);
