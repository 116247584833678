import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

import Switch from '../../../../components/Switch';
import ModalComponent from './ModalForm';
import Loading from '../../../../components/Loading';

import {getInitialValues} from '../../other';

const FirstOrder = ({ saleFirstOrders, changeChecker, onSubmit }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [count, setCount] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (saleFirstOrders && saleFirstOrders.type && saleFirstOrders.type !== count) {
            setCount(saleFirstOrders.type)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleFirstOrders]);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
    };

    if (!saleFirstOrders) {
        return <Loading />
    }

    const handleClick = () => {
        if (!openModal) {
            setOpenModal(true);
        }
    }

    return (
        <div className={classes.order}>
            <div className={classes.orderContent}>
                <ModalComponent
                    title={t('sales.firstOrder')}
                    saleFirstOrder={saleFirstOrders}
                    getInitialValues={getInitialValues}
                    open={openModal}
                    count={count}
                    onSubmit={onSubmit} 
                    handleClose={handleClose} 
                    handleClickSubmit={handleClickSubmit}
                    setCount={setCount} />
                <Typography className={classes.title}>{t('sales.firstOrder')}</Typography>
                <div className={classes.orderChange}>
                    <EditIcon className={classes.editIcon} onClick={() => handleClick()} />
                    <Switch 
                        className={classes.switchComponent} 
                        active={saleFirstOrders.available}
                        changeChecker={changeChecker} />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    discounts: {
        marginTop: 30,
        padding: 16,
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('600')]: {
            marginTop: 0,
            background: 'white',
        },
    },
    typography: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
    },
    title: {
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '19px',
    },
    order: {
        marginTop: 20,
        display: 'flex',
        height: 57,
        width: '100%',
        padding: '0 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
    },
    orderContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    orderChange: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editIcon: {
        width: 22,
        height: 22,
        color: '#476282',
        '&:hover': {
            color: '#326dff',
            cursor: 'pointer',
        }
    }
}))

export default FirstOrder;