/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { withFirebase } from "../../firebase";
import Login from "./login";
import { firebaseAPI } from "../../api";
import AlertComponent from "../../components/AlertComponent";

class LoginContainer extends Component {
	constructor() {
		super();
		this.state = {
			loadStatus: false,
		};
	}
	signIn = (data) => {
		this.state = {
			loadStatus: true,
		};

		firebaseAPI
			.getConfig(data.login)
			.then((res) => {
				if (res.error === true) {
					this.setState({
						alertText: "Произошла ошибка, попробуйте еще раз!",
						alertShow: true,
						alertType: "error",
						loadStatus: false,
					});
				} else {
					if (data.login === "osama-sushi") {
						alert("Перейдите в новую админку https://admin-osama.fun");
						this.setState({
							alertText: "Перейдите в новую админку https://admin-osama.fun",
							alertShow: true,
							alertType: "error",
							loadStatus: false,
						});
					} else {
						this.setState({
							alertShow: false,
							loadStatus: false,
						});
						this.props.updateFirebaseConfig(res);
						localStorage.setItem("@login", data.login);
					}
				}
			})
			.catch((res) => {
				this.setState({
					alertText: "Произошла ошибка, попробуйте еще раз!",
					alertShow: true,
					alertType: "error",
					loadStatus: false,
				});
			});
	};

	hideAlert = () => {
		this.setState({
			alertShow: false,
		});
	};

	render() {
		return (
			<>
				<Login onSubmit={this.signIn} loadStatus={this.state.loadStatus} />
				<AlertComponent
					type={this.state.alertType}
					text={this.state.alertText}
					status={this.state.alertShow}
					hideAlert={this.hideAlert}
				/>
			</>
		);
	}
}

export default withFirebase(LoginContainer);
