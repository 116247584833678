import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { updatePushNotifications } from "../../reducers/shops";

import Push from "./push";
import { withFirebase } from "../../firebase";
import AlertComponent from "../../components/AlertComponent";
import { connect } from "react-redux";
import color from "@material-ui/core/colors/amber";

class PushContainer extends Component {
	constructor() {
		super();
		this.state = {
			loadStatus: false,
			phone: [],
			key: null,
			alertText: "tres",
			alertShow: false,
			alertType: "error",
			productPush: false,
		};
	}

	componentDidMount() {
		this.getProductPushKey();
	}

	getProductPushKey = () => {
		this.setState({ loadStatus: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission/productPush`
			)
			.on("value", (snapshot) => {
				let productPush = snapshot.val();

				this.setState({ productPush, loadStatus: false });
			});
	};

	fetchPush = (
		bundleIds,
		body,
		audience,
		text,
		t,
		cityId,
		cities,
		firebase
	) => {
		const fetchOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		};

		const fetchRequests = bundleIds.map((bundleId) =>
			fetch(
				`https://epayment.com.ua/api/notifications/new/${bundleId}`,
				fetchOptions
			)
		);

		Promise.all(fetchRequests)
			.then((responses) =>
				Promise.all(responses.map((response) => response.json()))
			)
			.then((data) => {
				const dateTime = new Date().getTime();
				const timestamp = Math.floor(dateTime / 1000);
				var key = parseInt(timestamp / 86400);

				if (audience === "favorite") {
					this.setState({
						alertText: t("push.success"),
						alertShow: true,
						alertType: "success",
						loadStatus: false,
					});
				}

				if (audience === "city") {
					const city = cities.find((t) => t.spot_id === cityId);
					const cityKeyShop = city.key;

					let keyData =
						city.pushNotifications && city.pushNotifications[key]
							? city.pushNotifications[key]
							: [];
					keyData = [...keyData, text];

					firebase.bd
						.ref(
							`${
								JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
							}/settings/City/${cityKeyShop}/pushNotifications/${key}`
						)
						.update(keyData)
						.then((response) => {
							let pushNotificationsData = { ...city.pushNotifications };
							pushNotificationsData[key] = keyData;

							this.props.updatePushNotifications(
								cityKeyShop,
								pushNotificationsData
							);
							this.setState({
								alertText: t("push.success"),
								alertShow: true,
								alertType: "success",
								loadStatus: false,
							});
						})
						.catch((error) => {
							console.log(error);
						});
				}

				if (audience === "all" || audience === undefined) {
					cities.forEach((city, index) => {
						let keyData =
							city.pushNotifications && city.pushNotifications[key]
								? city.pushNotifications[key]
								: [];
						keyData = [...keyData, text];

						firebase.bd
							.ref(
								`${
									JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
								}/settings/City/${index}/pushNotifications/${key}`
							)
							.update(keyData)
							.then((response) => {
								this.setState({
									alertText: t("push.success"),
									alertShow: true,
									alertType: "success",
									loadStatus: false,
								});
							})
							.catch((error) => {
								console.log(error);
							});
					});
				}
			})
			.catch((error) => {
				console.log("error --->>> ", error);
				this.setState({
					alertText: t("push.error"),
					alertShow: true,
					alertType: "error",
					loadStatus: false,
				});
			});
	};

	sendPush = (data) => {
		const { cityId, phone, audience, text, product } = data;
		const {
			t,
			bundleId,
			notificationKey,
			cities,
			firebase,
			isBadge = false,
		} = this.props;
		let topic = "/topics/";

		this.setState({
			loadStatus: true,
		});

		if (audience === "favorite") {
			topic += phone.replace(/-/g, "");
		} else if (audience === "city") {
			topic += cityId;
		} else if (audience === "all" || audience === undefined) {
			topic += "all";
		}

		let notification = {
			body: text,
		};

		let body = {
			topic: topic,
			notification,
			apns: {
				headers: {
					"apns-priority": "10",
				},
				payload: {
					aps: {
						badge: isBadge ? 1 : 0,
					},
					mutable_content: true,
					content_available: true,
				},
			},
		};

		if (product) {
			body = {
				...body,
				notification: {
					...notification,
				},
				data: {
					product_id: product,
				},
				android: {
					notification: {
						click_action: `${bundleId}.FIREBASE_DATA`,
					},
				},
			};
		}

		let bundleArray = [];
		if (bundleId?.includes("/")) {
			const splitBundle = bundleId.split("/");
			bundleArray = [...splitBundle];
		} else {
			bundleArray = [bundleId];
		}
		console.log("bund", bundleId);

		this.fetchPush(
			bundleArray,
			body,
			audience,
			text,
			t,
			cityId,
			cities,
			firebase
		);
	};

	addPhone = (data) => {
		let phoneList = this.state.phone;
		phoneList.push(data.phone);
		this.setState({
			phone: phoneList,
		});
	};

	deletePhone = (key) => {
		let phoneList = this.state.phone;
		phoneList.splice(key, 1);
		this.setState({
			phone: phoneList,
		});
	};

	hideAlert = () => {
		this.setState({
			alertShow: false,
		});
	};

	render() {
		return (
			<>
				<Push
					onSubmit={this.sendPush}
					productPush={this.state.productPush}
					loadStatus={this.state.loadStatus}
					phone={this.state.phone}
					addPhone={this.addPhone}
					deletePhone={this.deletePhone}
				/>
				{this.state.alert}
				<AlertComponent
					type={this.state.alertType}
					text={this.state.alertText}
					status={this.state.alertShow}
					hideAlert={this.hideAlert}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	console.log("state --- >>>", state);
	return {
		cities: state.shops.shops,
		notificationKey: state.shops.appSettings.notificationKey,
		bundleId: state.shops.appSettings.bundleId,
		isBadge: state.shops.adminPermission.isBadge,
	};
};

export default connect(mapStateToProps, { updatePushNotifications })(
	withTranslation()(withFirebase(PushContainer))
);
