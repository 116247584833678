import {reset} from 'redux-form';

export const resetForms = (dispatch) => {
    dispatch(reset('PushForm'));
    dispatch(reset('CategoriesForm'));
    dispatch(reset('ProductsForm'));
    dispatch(reset('AddCategories'));
    dispatch(reset('AddProducts'));
    dispatch(reset('TableForm'));
};

export  const checkSettingsDropdown = ({ openCategory, cityId, handleOpen}) => {
    const isMenuSelector = document.location.pathname === '/page/menu/categories' && openCategory === null;
    const isPushSelector = document.location.pathname === '/page/push' && openCategory === null;
    const isDiscountSelector = document.location.pathname === '/page/discounts/' + cityId;

    if (isMenuSelector || isPushSelector || isDiscountSelector) {
      handleOpen('settings'); 
    }
}