import React, { Component, useState, useEffect } from "react";

import DisableApp from "./disableApp";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateKeyShop, editShops, updateSettings } from "../../reducers/shops";
import { withFirebase } from "../../firebase";

class DisableAppContainer extends Component {
	constructor() {
		super();

		this.state = {
			checked: true,
			keyShop: null,
		};
	}

	componentDidMount() {
		this.props.updateKeyShop(this.props.match.params.city);
		this.setState({
			keyShop: this.props.match.params.city,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.shops.orderAvailable !== this.props.shops.orderAvailable) {
			this.updateStatusApp();
		}
	}

	// const [checked, setChecked] = useState(true);
	// const [keyShop, setKeyShop] = useState(null);
	// useEffect(() => {
	// 	props.updateKeyShop(props.match.params.city);
	// 	// setKeyShop(this.props.match.params.city);
	// }, []);

	// const updateStatusApp = () => {
	// 	let value = props.shops[props.match.params.city].orderAvailable
	// 		? false
	// 		: true;
	// 	const isDebug = props.isDebug;
	// 	props.editShops({
	// 		key: props.match.params.city,
	// 		params: "orderAvailable",
	// 		value,
	// 	});
	// 	props.firebase.bd
	// 		.ref(
	// 			`${isDebug ? "debug" : ""}/settings/City/${
	// 				this.props.match.params.city
	// 			}/`
	// 		)
	// 		.update({ orderAvailable: value });
	// 	if (localStorage.getItem("@login") === "osama-sushi") {
	// 		props.firebase.bd
	// 			.ref(`${isDebug ? "debug" : ""}/updates`)
	// 			.update({ lastUpdateInMenu: Date.now() });
	// 	}
	// };

	// const updateAlert = (data) => {
	// 	const isDebug = JSON.parse(localStorage.getItem("isDebug"));
	// 	if (data.text !== props.appSettings.closedSpotAlertDescription) {
	// 		props.updateSettings({
	// 			params: "closedSpotAlertDescription",
	// 			value: data.text,
	// 		});
	// 		props.firebase.bd
	// 			.ref(`${isDebug ? "debug" : ""}/settings/appSettings/`)
	// 			.update({ closedSpotAlertDescription: data.text });
	// 	}
	// };

	updateStatusApp = () => {
		let value = this.props.shops[this.props.match.params.city].orderAvailable
			? false
			: true;
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		this.props.editShops({
			key: this.props.match.params.city,
			params: "orderAvailable",
			value,
		});
		this.props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/settings/City/${
					this.props.match.params.city
				}/`
			)
			.update({ orderAvailable: value });
		if (localStorage.getItem("@login") === "osama-sushi") {
			this.props.firebase.bd
				.ref(`${isDebug ? "debug" : ""}/updates`)
				.update({ lastUpdateInMenu: Date.now() });
		}

		setTimeout(() => alert("Готово"), 300);
	};

	updateAlert = (data) => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		if (data.text !== this.props.appSettings.closedSpotAlertDescription) {
			this.props.updateSettings({
				params: "closedSpotAlertDescription",
				value: data.text,
			});
			this.props.firebase.bd
				.ref(`${isDebug ? "debug" : ""}/settings/appSettings/`)
				.update({ closedSpotAlertDescription: data.text });
		}
		alert("Готово");
	};

	render() {
		return (
			<>
				<DisableApp
					updateStatusApp={this.updateStatusApp}
					keyShop={this.props.match.params.city}
					closedAlert={this.props.closedAlert}
					shops={this.props.shops[this.props.match.params.city]}
					alert={this.props.appSettings.closedSpotAlertDescription}
					editShops={this.editShops}
					updateAlert={this.updateAlert}
					isDebug={this.props.isDebug}
				/>
				{/* <DisableApp
				updateStatusApp={updateStatusApp}
				keyShop={props.match.params.city}
				closedAlert={props.closedAlert}
				shops={props.shops[props.match.params.city]}
				alert={props.appSettings.closedSpotAlertDescription}
				editShops={editShops}
				updateAlert={updateAlert}
			/> */}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, {
	updateKeyShop,
	editShops,
	updateSettings,
})(withRouter(withFirebase(DisableAppContainer)));
