import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Loading from "../../../../components/Loading";
import Categories from "./listCategories";
import Button from "@material-ui/core/Button";
import ModalComponent from "../../../../components/ModalComponent";

import { trim } from "../other";

import dndIcon from "./assets/dnd.svg";
import useStyles from "./styles";

const SortProducts = (props) => {
	const {
		openModal,
		setOpenModal,
		handleClose,
		handleClickSubmit,
		categoriesList,
		onDragEnd,
		activeProducts,
		language,
		hiddenCategories,
	} = props;

	const classes = useStyles();

	return (
		<div>
			<ModalComponent
				title="Подтвердите действие!"
				description={`Хотите изменить сортировку товаров?`}
				open={openModal}
				handleClose={handleClose}
				handleClickSubmit={handleClickSubmit}
			/>
			<div className={classes.sortModeCategories}>
				{categoriesList ? (
					<Categories
						categories={categoriesList}
						hiddenCategories={hiddenCategories}
						language={language}
						defaultLanguage={language}
						open={props.open}
						activeCategory={props.activeCategory}
						updateCategory={props.updateCategory}
					/>
				) : (
					"Категорий пока что нету"
				)}
			</div>
			<div className={classes.sortModeProducts}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="sortMode">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								// style={getListStyle(snapshot.isDraggingOver)}
							>
								{activeProducts &&
									activeProducts.map((item, index) => {
										return (
											<Draggable
												key={index}
												draggableId={String(index)}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														key={index}
														className={classes.bannerWrap}
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div key={index} className={classes.banner}>
															<div className={classes.bannerContentWrap}>
																<div className={classes.imageWrap}>
																	<div
																		className={classes.image}
																		style={{
																			backgroundImage: `url(${item.photo})`,
																		}}
																	></div>
																</div>
																<div className={classes.titleWrap}>
																	<div className={classes.title}>
																		{
																			item.name[language] && item.name[language]
																			// trim(item.name[language], 20)
																		}
																	</div>
																</div>
															</div>
														</div>
														<div className={classes.dnd}>
															<img src={dndIcon} alt="dnd" />
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{!activeProducts && <Loading />}
			</div>
			<div className={classes.buttonWrap}>
				<Button
					onClick={() => setOpenModal(true)}
					variant="contained"
					color="primary"
					className={classes.button}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
};

export default SortProducts;
